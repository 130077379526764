








































































































































































import { Component, Vue } from 'vue-property-decorator'
import { mdiDiscord, mdiTelegram } from '@mdi/js'
import {getCheckoutStats, getDOM, getSpotPairs, getStaticLaunchpadConf, TradePair} from '@/clients/cpinblocks'
import { Currency, TokenType } from '@/types'
import { env } from '@/utils'
import AddCheckout from '@/components/AddCheckout.vue'
import { BigNumber } from 'bignumber.js'

@Component({ components: { AddCheckout } })
export default class Launchpad extends Vue {

  checkoutAmount = new BigNumber(100)
  checkoutShow = false
  checkoutToken: Currency | null = null
  checkoutTokenType: TokenType | null = null

  loading = true
  mdiDiscord = mdiDiscord
  mdiTelegram = mdiTelegram

  projects: any[] = []

  currenciesWithLimitedSupply = [ 'LYDIA' ]
  remaining: any = {}

  get appEnv (): string {
    return env('VUE_APP_ENV')
  }

  async onBuy (token: string) {
    this.checkoutToken = token as Currency
    if (this.checkoutToken === ('LYDIA' as Currency)) {
      this.checkoutTokenType = 'OPTION'
    } else {
      this.checkoutTokenType = 'MAIN'
    }
    this.checkoutShow = true
  }

  private checkoutEnabled (pairs: TradePair[], token: Currency): boolean {
    for (const tp of pairs) {
      if (tp.productCurrency === token &&
          tp.unitPriceCurrency === 'IBXE' &&
          tp.unitPriceType === 'MAIN' &&
          tp.checkout === 'ENABLED') {
        return true
      }
    }
    return false
  }

  async mounted () {
    const pairs = await getSpotPairs()
    this.projects = (await getStaticLaunchpadConf()).filter((p) => p.type === 'ico')
    for (let o of this.projects) {
      o.image = require(`@/assets/tokens/${o.name.toLowerCase()}.svg`)
      o.totalHolders = (await this.getTotalHolders(o.name as Currency)).toString()
      o.checkout = this.checkoutEnabled(pairs, o.name as Currency)
      if (this.currenciesWithLimitedSupply.indexOf(o.name) >= 0) {
        let result = new BigNumber(0)
        const dom1 = await getDOM(o.name as Currency, 'OPTION', 'IBXE', 'MAIN', 100)
        for (let s of dom1.sells) {
          result = result.plus(s.quantity);
        }
        const dom2 = await getDOM(o.name as Currency, 'OPTION', 'IBXD', 'MAIN', 100)
        for (let s of dom2.sells) {
          result = result.plus(s.quantity);
        }
        this.remaining[o.name] = result
      }
      if (o.name !== 'POWER') {
        // For now we hide real data ...
        // o.raisedTotalUser = (await this.getCheckoutStats(o.name as Currency))[0].toString()
        // o.raisedTotalAmount = (await this.getCheckoutStats(o.name as Currency))[1].toString() + ' €'
        o.raisedTotalUser = ''
        o.raisedTotalAmount = ''
      }
    }
    this.loading = false
  }

  // Disabled for now
  async getTotalHolders (currency: Currency): Promise<Number> {
    // return await getTotalHolders(currency)
    return 0
  }

  async getCheckoutStats (currency: Currency): Promise<Number[]> {
    // FIXME: removetype when API is compliant with lack of token type parameter
    return await getCheckoutStats(currency, 'LONG')
  }

  goToProject (project: any) {
    if (project.router) {
      this.$router.push({ name: project.router })
    } else {
      this.openInNewTab(project.url)
    }
  }

  openInNewTab (url: string): void {
    window.open(url, '_blank')
  }
}
